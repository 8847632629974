import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

export default function UploadPhoto() {
    const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
      };
    
      // Handle file upload
      const handleUpload = async () => {
        if (!selectedFile) {
          alert("Please select an image to upload");
          return;
        }
    
        const formData = new FormData();
        formData.append('photo_name', selectedFile); // 'photo' is the key expected by the server
    
        try {
          
          navigate("/")
        
          // const response = await axios.post('http://localhost:8080/api/photo/add', formData);
          const response = await axios.post('https://api.rastriyamuktikranti.com/api/photo/add', formData);
         
         
        } catch (error) {
          console.error('Error uploading photo:', error);
          alert('Failed to upload photo');
        }
      };
  return (
    <div className='p-32 flex flex-col justify-center items-center my-4'>
    <input 
      type="file" 
      accept="image/*" 
      onChange={handleFileChange} 
      className="hidden  sm:block"
    />
    <button 
      className="bg-orange-400 my-6 flex justify-center items-center hover:bg-red-500 transition ease-in rounded-md text-white px-3 py-1" 
      onClick={handleUpload}
    >
      Upload Photo
    </button>
  </div>
  )
}
