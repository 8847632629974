import React from "react";
import joinpic from "../assets/imagesgallery/pic222.jpeg";
import { Link } from "react-router-dom";

export default function Journey() {
  return (
    <div className="flex justify-center items-center rounded-md bg-orange-500 p-1 sm:p-10">
     
      <div className="flex  w-full justify-between rounded-lg items-center">
        <div className="bg-black w-full hidden md:flex  ">
          <img className="h-screen w-full object-cover" src={joinpic} alt="" />
        </div>
        {/* right div  */}
        <div className=" myfont2 p-10 sm:p-10 bg-slate-800 w-full flex-col gap-4 justify-center  items-start h-screen flex">
          <div className="xl:text-7xl text-4xl  sm:text-5xl  flex flex-nowrap text-white  ">
            <h1>Become Part of the Journey</h1>
          </div>
          <div className="text-xl sm:text-2xl text-white ">
            <h3>Become the Change You Aspire to See</h3>
          </div>
          <div className="sm:text-4xl text-2xl text-white ">
           <Link to="/join"><h2 className="bg-orange-400 px-3 py-1 rounded-md">
              JOIN RKM{" "}
            </h2></Link> 
          </div>
          <div>
            <p className="text-sm lg:text-lg text-gray-100">
              RKM is wholeheartedly dedicated to fostering a robust and
              progressive Nepal, a vision that can only be realized through the
              unwavering trust and steadfast support of the Nepali people. Join
              us in this unparalleled movement, driving transformative change
              that is positively impacting all segments of society. Be a part of
              the rise of a New Nepal under the dynamic leadership of Rajendra
              Mahato.
            </p>
          </div>
          {/* squares  */}
          <div className="flex   text-white text-[2vw]  justify-center items-center gap-5 ">
            <div className="flex justify-center items-center ">
              <h2 className="flex text-center h-[11vw] w-[11vw]  items-center bg-blue-500">
                JOIN AS VOLUNTEER
              </h2>
            </div>
            <div className="flex text-center items-center ">
              <h2 className="flex items-center text-center h-[11vw] w-[11vw]  bg-pink-400">
                BECOME A MEMBER
              </h2>
            </div>
            <div className="flex justify-center items-center ">
              <h2 className="flex text-center items-center h-[11vw] w-[11vw]  bg-orange-400">
                MAKE A DONATION
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
