import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img1 from "../assets/imagesgallery/img1.jpeg";
import img2 from "../assets/imagesgallery/img2.jpeg";
import img3 from "../assets/imagesgallery/img3.jpeg";
import img4 from "../assets/imagesgallery/img4.jpeg";
import img5 from "../assets/imagesgallery/img5.jpeg";
import img6 from "../assets/imagesgallery/img6.jpeg";
import img7 from "../assets/imagesgallery/img7.jpeg";
import img8 from "../assets/imagesgallery/img8.jpeg";

export default function Presidents() {
  const sliderRef = useRef(null); // Ref to control slider manually

  const pics = [
    { img: img1, heading: "Rajendra Mahto", subheading: "(President)" },
    { img: img2, heading: "Kamla Gurung", subheading: "(Central Executive Member)" },
    { img: img3, heading: "Rajb Kumar Jha", subheading: "" },
    { img: img4, heading: "Keshav Kumar Jha", subheading: "" },
    { img: img5, heading: "Suman Saymi", subheading: "" },
    { img: img6, heading: "Budh Chiring Moktan", subheading: "" },
    { img: img7, heading: "Rabindra Shrestha", subheading: "" },
    { img: img8, heading: "Jumtara Pun Magar", subheading: "" },
  ];

  const settings = {
    infinite: true, // Infinite loop
    speed: 700, // Speed of transition
    slidesToShow: 4, // Show 4 slides per view
    slidesToScroll: 1, // Scroll 1 slide at a time
    responsive: [
      {
        breakpoint: 1024, // For medium screens (tablet size)
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600, // For small screens (mobile)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // For very small screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="relative w-full py-5 overflow-hidden">
      {/* Carousel Slider */}
      <Slider ref={sliderRef} {...settings}>
        {pics.map((pic, index) => (
          <div key={index} className="relative myfont bg-white px-1">
            <img
              src={pic.img}
              alt={`carousel-img-${index}`}
              className="w-full h-full sm:h-[70vh] max-h-screen object-cover rounded-lg shadow-lg"
            />
            {/* Overlay Text */}
            <div className="absolute bottom-0 left-0 right-0 p-4 bg-black bg-opacity-60 text-white">
              <h2 className="text-xl md:text-2xl myfont font-bold mb-1 text-center">
                {pic.heading}
              </h2>
              <p className="text-sm md:text-lg text-center myfont">{pic.subheading}</p>
            </div>
          </div>
        ))}
      </Slider>

      {/* Manual Navigation Buttons */}
      <button
        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-transparent text-white p-2 opacity-60 rounded-full z-10"
        onClick={() => sliderRef.current.slickPrev()}
      >
        <svg
          className="h-12 w-12 text-white"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <polyline points="12 8 8 12 12 16" />
          <line x1="16" y1="12" x2="8" y2="12" />
        </svg>
      </button>
      <button
        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-transparent text-white  opacity-60  p-2 rounded-full z-10"
        onClick={() => sliderRef.current.slickNext()}
      >
        <svg
          className="h-12 w-12 text-white"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <polyline points="12 16 16 12 12 8" />
          <line x1="8" y1="12" x2="16" y2="12" />
        </svg>
      </button>
    </div>
  );
}
