import React, { useRef, useState } from "react";
import "remixicon/fonts/remixicon.css";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import Share from "../smallComponent/Share";
import { useUserStore } from "../hooks/useUserStore";
import { useNavigate } from "react-router-dom";
import axios from "axios";
function Navigation() {
  // dialogue show
 
  const navDialogeRef = useRef(null);
  function handleMenu() {
    if (navDialogeRef.current) {
      navDialogeRef.current.classList.toggle("hidden");
    }
  }

  const shareRef = useRef(null);
  function handleShare() {
    console.log("bukdbwk");

    if (shareRef.current) {
      shareRef.current.classList.toggle("hidden");
    }
  }

  const dropdownRef = useRef(null);
  function handleDropdown() {
    console.log("bukdbwk");

    if (dropdownRef.current) {
      dropdownRef.current.classList.toggle("hidden");
    }
  }

  const url = "http://localhost:3001/login";
  const text = "Check this out!";
  const user = useUserStore((state) => state.user);
  console.log({ user });
  const navigate = useNavigate();

  const logoutUser = useUserStore((state) => state.logout);
  const handleLogout = () => {
    logoutUser();
    navigate("/");
  };
 
  return (
    <>
      {/* Navbar 1  */}

      <section className="z-50 max-h-20 w-full bg-orange-100 shadow-lg  ">
        <nav className="sm:px-20 px-3 text-sm font-mono font-semibold flex justify-between items-center py-1">
          <div className="flex justify-center gap-3 md:gap-8 items-center">
            <div className="flex justify-center items-center gap-1">
              <svg
                class="h-4 w-4 text-neutral-800"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
              </svg>
              <h1 className="hidden sm:flex">8630158390</h1>
            </div>
            <div className="flex justify-center items-center gap-1">
              <svg
                class="h-4 w-4 text-neutral-800"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />{" "}
                <polyline points="22,6 12,13 2,6" />
              </svg>
              <h1 className="hidden sm:flex">rashtriyamuktikranti@gmail.com</h1>
            </div>

            <div className="flex justify-center items-center gap-1">
              <div class="relative flex  text-left">
                <div className="flex justify-center items-center gap-1">
                  <button
                    onClick={handleShare}
                    class="hidden  sm:flex "
                    type="button"
                  >
                    Share
                  </button>
                  <svg
                    onClick={handleShare}
                    class="h-5 cursor-pointer w-5 pe-1 flex justify-center itemsc4 text-gray-800"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />{" "}
                    <line x1="10" y1="14" x2="21" y2="3" />{" "}
                    <path d="M21 3L14.5 21a.55 .55 0 0 1 -1 0L10 14L3 10.5a.55 .55 0 0 1 0 -1L21 3" />
                  </svg>
                </div>
                {/* modal  */}
                <div
                  ref={shareRef}
                  id="popup-modal"
                  tabindex="-1"
                  class=" overflow-x-hidden hidden fixed top-0 flex justify-center z-50 items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                >
                  <div
                    ref={shareRef}
                    class="relative p-5 w-full max-w-md max-h-full"
                  >
                    <div class="relative bg-white rounded-lg shadow-lg flex">
                      {/* close modal button */}
                      <button
                        onClick={handleShare}
                        type="button"
                        class="absolute top-2 end-2.5  bg-transparent hover:bg-gray-300 text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                        data-modal-hide="popup-modal"
                      >
                        <svg
                          class="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span class="sr-only">Close modal</span>
                      </button>

                      <div class="py-4 flex gap-5 justify-center items-center  h-28 w-full text-center">
                        <Share url={url} text={text}></Share>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between gap-4 md:gap-16 items-center">
            <div className="flex justify-center cursor-pointer gap-3">
              <div className="cursor-pointer">
                <svg
                  class="sm:h-5 sm:w-5 h-4 w-4  text-neutral-800"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                </svg>
              </div>

              <div>
                <svg
                  class="sm:h-5 sm:w-5 h-4 w-4  text-neutral-580"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
                </svg>
              </div>
              <div>
                {" "}
                <svg
                  class="sm:h-5 sm:w-5 h-4 w-4  text-neutral-700"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  {" "}
                  <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />{" "}
                  <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" />
                </svg>
              </div>
              <div>
                <svg
                  class="sm:h-5 sm:w-5 h-4 w-4  text-neutral-800"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="18" cy="5" r="3" />{" "}
                  <circle cx="6" cy="12" r="3" />{" "}
                  <circle cx="18" cy="19" r="3" />{" "}
                  <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />{" "}
                  <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
                </svg>
              </div>
            </div>

            <div className="flex justify-center cursor-pointer gap-1 md:gap-3">
              <div>
                <svg
                  class="sm:h-8 sm:w-8 h-6 w-6  text-neutral-800"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  {" "}
                  <path stroke="none" d="M0 0h24v24H0z" />{" "}
                  <path d="M5 7h7m-2 -2v2a5 8 0 0 1 -5 8m1 -4a7 4 0 0 0 6.7 4" />{" "}
                  <path d="M11 19l4 -9l4 9m-.9 -2h-6.2" />
                </svg>
              </div>
            </div>
          </div>
        </nav>
      </section>

      {/* Navbar 2  */}
      <section className="z-40 max-h-20 w-full absolute   bg-slate-100 backdrop-blur-sm shadow-lg p-2 ">
        <nav className="flex  max-h-20 justify-between px-2 items-center">
          <div className="flex justify-center items-center gap-4">
            <div className="h-[8vh] w-[8vh]">
              <img
                className=" hover:animate-spin h-[100%] w-[100%]"
                src={logo}
                alt="logo"
              />
            </div>
            <h1 className="hidden md:block text-3xl text-gray-900 font-semibold md:text-left ">
              Rastriya Mukti Kranti
            </h1>
          </div>

          {/* Bada navbar (Upper navbar)  */}
          <div className="flex text-xs sm:text-sm font-bold items-center px-2 justify-between gap-4 md:gap-7">
            <div class="relative inline-block text-left">
              <button
                className="flex justify-center items-center gap-1"
                onClick={handleDropdown}
              >
                <h1 className="text-gray-900">GALLERY</h1>
                <span>
                  <svg
                    class="h-6 w-6 text-gray-900"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />{" "}
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </span>
              </button>

              <div
                id="dropdown"
                ref={dropdownRef}
                class="absolute hidden bg-slate-50 right-0 z-50 mt-3 origin-top-right rounded-md backdrop-blur-lg shadow-lg ring-1 ring-black ring-opacity-5"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabindex="-1"
              >
                <div class="py-1 px-10 z-50 " role="none">
                  <Link
                    onClick={handleDropdown}
                    to="/photos"
                    class="block  hover:text-blue-700 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-0"
                  >
                    Images
                  </Link>

                  <Link
                    onClick={handleDropdown}
                    to="/videos"
                    class="block hover:text-blue-700  py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-0"
                  >
                    Videos
                  </Link>
                  
                  <Link
                    onClick={handleDropdown}
                    to="/article"
                    class="block hover:text-blue-700 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-0"
                  >
                    Article
                  </Link>
                </div>
              </div>
            </div>

            <Link to="/">
              <h4 className="text-gray-800  hover:text-orange-400 flex gap-1 transition ease-in">
                <span className="text-gray-800 hidden sm:block">RKM</span> LIVE
              </h4>
            </Link>
            <Link to="/contact">
              <h4 className=" text-gray-800 hover:text-orange-400 transition ease-in">
                RESOURCES
              </h4>
            </Link>

            <div className="flex justify-center items-center gap-2">
              <Link to="/join">
                <button className="bg-orange-400 hover:bg-orange-500 transition ease-in hidden  sm:block rounded-md text-white px-6 py-1 ">
                  Join
                </button>
              </Link>
              <Link to="/donate">
                <button className="bg-orange-400 hover:bg-red-500  hidden sm:block transition ease-in  rounded-md text-white  px-3 py-1 ">
                  Donate
                </button>
              </Link>
              {user?.usertype === "admin" ? ( <Link to="/upload">
                <button className="bg-orange-400 hover:bg-red-500  hidden sm:block transition ease-in  rounded-md text-white  px-3 py-1 ">
                  Upload
                </button>
              </Link>):("")}
             
   
            </div>

            <div className="justify-center items-center gap-2">
              {user?.usertype === "superadmin" ? (
                <>
                  <Link to="/leader">
                    <button className="bg-blue-500 hover:bg-blue-600  hidden sm:block transition ease-in  rounded-md text-white  px-3 py-1 ">
                      Add a Leader
                    </button>
                  </Link>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <button onClick={handleMenu}>
            <svg
              className="h-8 w-8 text-gray-900"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="3" y1="12" x2="21" y2="12" />
              <line x1="3" y1="6" x2="21" y2="6" />
              <line x1="3" y1="18" x2="21" y2="18" />
            </svg>
          </button>

          {/* Navbar Chota wala (Side View) */}

          <div
            ref={navDialogeRef}
            className="transition pt-6 hidden ease-in bg-slate-50 fixed px-4 z-40 py-3 h-screen w-full right-0 top-0"
          >
            <div className="flex font-bold justify-between items-center">
              <h1 className="text-orange-500 text-3xl px-6 ">R M K</h1>

              <button onClick={handleMenu}>
                <svg
                  className="h-8 w-8 text-gray-900"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </button>
            </div>

            {/* Links */}
            <div className="flex flex-col justify-between gap-5">
              <div className="flex flex-col justify-between min-h-[80vh]">
                <div className="mt-10 text-md font-semibold flex flex-col w-full gap-2 px-0">
                  {!user ? (
                    <>
                      <div className="w-full hover:animate-ping rounded bg-blue-800 shadow-md items-center hover:bg-blue-900 text-gray-100 hover:text-green-100 py-1 flex">
                        <button className="w-full" onClick={handleMenu}>
                          <Link
                            className="w-full flex justify-center"
                            to="/login"
                          >
                            <h4 className=" hover:text-gray-100">Sign In</h4>
                          </Link>
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="w-full hover:animate-ping  rounded bg-emerald-500 shadow-md items-center hover:bg-emerald-600 text-gray-100 hover:text-green-100 py-1 flex">
                    <button className="w-full  " onClick={handleMenu}>
                      <Link className="w-full flex justify-center" to="/">
                        <h4 className=" hover:text-gray-100">Infographics</h4>
                      </Link>
                    </button>
                  </div>

                  <div className="w-full hover:animate-ping rounded bg-blue-800 hover:bg-blue-900 text-gray-100 py-1 shadow-md  items-center flex">
                    <button className="w-full" onClick={handleMenu}>
                      <Link
                        className="w-full flex justify-center"
                        to="/contact"
                      >
                        <h4 className=" ">My RMK</h4>
                      </Link>
                    </button>
                  </div>

                  <div className="w-full hover:animate-ping rounded bg-purple-800 hover:bg-purple-900 text-gray-100 shadow-md py-1  items-center flex">
                    <button className="w-full" onClick={handleMenu}>
                      <Link
                        className="w-full flex justify-center"
                        to="/contact"
                      >
                        <h4 className=" ">State Websites</h4>
                      </Link>
                    </button>
                  </div>

                  <div className="w-full hover:animate-ping rounded bg-orange-500 text-white  hover:bg-orange-600 shadow-md py-1 items-center flex">
                    <button className="w-full" onClick={handleMenu}>
                      <Link className="w-full flex justify-center" to="/join">
                        <h4 className=" ">Join RMK</h4>
                      </Link>
                    </button>
                  </div>

                  <div className="w-full hover:animate-ping rounded bg-orange-400 text-white  hover:bg-orange-500 shadow-md py-1 items-center flex">
                    <button className="w-full" onClick={handleMenu}>
                      <Link className="w-full flex justify-center" to="/donate">
                        <h4 className=" ">Make a Donation</h4>
                      </Link>
                    </button>
                  </div>

                  <div className="w-full hover:animate-ping rounded bg-cyan-400 text-white  hover:bg-cyan-600 py-1 shadow-md items-center flex">
                    <button className="w-full" onClick={handleMenu}>
                      <Link className="w-full flex justify-center" to="/about">
                        <h4 className=" ">About</h4>
                      </Link>
                    </button>
                  </div>
                  <div className="w-full hover:animate-ping rounded  hover:bg-orange-500 text-white bg-orange-400 shadow-md py-1 items-center flex">
                    <button className="w-full" onClick={handleMenu}>
                      <Link className="w-full flex justify-center" to="/join">
                        <h4 className=" ">RMK Yatra</h4>
                      </Link>
                    </button>
                  </div>

                  <div className="w-full hover:animate-ping rounded  hover:bg-green-600 text-white bg-green-500 shadow-md py-1 items-center flex">
                    <button className="w-full" onClick={handleMenu}>
                      <Link className="w-full flex justify-center" to="/join">
                        <h4 className=" ">Election Corner</h4>
                      </Link>
                    </button>
                  </div>
                  <div className="w-full hover:animate-ping rounded bg-purple-900 text-white  hover:bg-purple-950 py-1 items-center flex">
                    <button className="w-full" onClick={handleMenu}>
                      <Link className="w-full flex justify-center" to="/join">
                        <h4 className=" ">RMK Live</h4>
                      </Link>
                    </button>
                  </div>
                  <div className="hover:animate-ping">
                    {user ? (
                      <>
                        <button
                          onClick={handleLogout}
                          className="w-full rounded bg-purple-900 text-white  hover:bg-purple-950 py-1 flex justify-center items-center gap-2"
                        >
                          Sign Out
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              {/* footer (contacts) */}

              <div className="flex relative flex-end font-bold gap-2  justify-center items-center h-[10vh] bottom-0 ">
                <div>
                  <h3>Connect with us :</h3>
                </div>
                <div className="flex justify-start gap-2 items-center">
                  <h3>
                    <svg
                      class="h-5 w-5 text-blue-500"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      {" "}
                      <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                    </svg>
                  </h3>
                  <h3>
                    <svg
                      class="h-5 w-5 text-sky-500"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      {" "}
                      <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
                    </svg>
                  </h3>
                  <h3>
                    <svg
                      class="h-5 w-5 text-red-500"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      {" "}
                      <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />{" "}
                      <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" />
                    </svg>
                  </h3>
                  <h3>
                    <svg
                      class="h-5 w-5 text-neutral-600"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      {" "}
                      <circle cx="18" cy="5" r="3" />{" "}
                      <circle cx="6" cy="12" r="3" />{" "}
                      <circle cx="18" cy="19" r="3" />{" "}
                      <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />{" "}
                      <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
                    </svg>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
}

export default Navigation;
